@import './reset.scss';
@import './colors.scss';

* {
  box-sizing: border-box;
}
::selection {
  color: $white;
  background: $concrete;
}
html {
  overflow-x: hidden;
  font-size: 15px;
  font-family: 'Source Sans Pro', sans-serif;
  background: $ink;
  perspective: 1000px;
}
#root {
  perspective: 2400px;
}

.icon-github {
  margin-right: 10px;
  width: 17px;
  height: 17px;
  background-color: $steel;
  -webkit-mask-image: url(../assets/github-17.svg);
  mask-image: url(../assets/github-17.svg);
  transition: 0.1s ease all;
}

header {
  background: rgba(#fff, 0.02);
}
.header__content {
  margin: 0 auto;
  display: flex;
  align-items: center;
  max-width: 980px;
  height: 64px;
}
.header__sponsor {
  margin-left: auto;
  display: flex;
  color: $steel;
  font-size: 15px;
  font-weight: 600;
  align-items: center;
  & img {
    margin-top: 3px;
    margin-left: 4px;
  }
}
nav {
  display: flex;
  font-weight: 600;
  & a {
    display: flex;
    align-items: center;
    color: $steel;
    text-decoration: none;
    transition: 0.1s ease all;
    &:not(:last-child) {
      margin-right: 48px;
    }
    &:hover {
      color: lighten($steel, 20%);
      & .icon-github {
        background-color: lighten($steel, 20%);
      }
    }
    &.active {
      color: $white;
      & .icon-github {
        background-color: $white;
      }
    }
  }
}

.hero {
  margin: 0 auto;
  max-width: 980px;
  height: calc(100vh - 64px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: ew-resize;
}
.hero__title {
  cursor: default;
  color: $white;
  font-size: 36px;
  font-weight: 700;
}
.hero__desc {
  cursor: default;
  max-width: 450px;
  margin-top: 12px;
  color: $white;
  font-size: 18px;
  line-height: 1.5;
  text-align: center;
}
.hero__cta {
  position: relative;
  margin-top: 32px;
  z-index: 2;
}
.hero__placeholder {
  width: 700px;
  transform: translateY(-100px);
  z-index: -1;
}
.hero__visual {
  position: absolute;
  top: 64px;
  bottom: 0;
  width: 100%;
  height: calc(100vh - 64px);
  z-index: -1;
  & canvas {
    display: block;
  }
}

.recon {
  width: 500px;
  height: 500px;
  border-radius: 32px;
  background: rgba($steel, 0.2);
  transform: rotateX(45deg) rotateZ(45deg);
}
.recon__cloud {
  position: absolute;
  top: -150px;
  left: -300px;
  width: 1000px;
  height: 700px;
  background: url(../assets/cloud2.png) center center no-repeat;
  background-size: 100%;
  transform-origin: center center;
}
.recon__cams {
  position: absolute;
  top: 0;
}
.recon-cam {
  position: absolute;
  width: 80px;
  height: 60px;
  background: url('../assets/camera.svg') center center no-repeat;
  background-size: contain;
  &--1 {
    top: 120px;
    left: 20px;
    opacity: 0.3;
  }
  &--2 {
    top: 160px;
    left: 180px;
  }
  &--3 {
    width: 85px;
    height: 65px;
    top: 250px;
    left: 290px;
    opacity: 0.3;
  }
  &--4 {
    width: 85px;
    height: 65px;
    top: 320px;
    left: 430px;
    opacity: 0.3;
  }
}
.recon-img {
  position: absolute;
  width: 60px;
  height: 40px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  border-radius: 4px;
  box-shadow: 0 50px 20px rgba(0, 0, 0, 0.2);
  transform: rotateY(-40deg) rotateZ(-25deg) rotateX(20deg);
  &--1 {
    top: 140px;
    left: 50px;
    background-image: url(../assets/1.jpg);
    z-index: 2;
  }
  &--2 {
    top: 180px;
    left: 210px;
    background-image: url(../assets/2.jpg);
    z-index: 2;
  }
  &--3 {
    top: 270px;
    left: 325px;
    background-image: url(../assets/3.jpg);
    z-index: 2;
  }
  &--4 {
    top: 343px;
    left: 466px;
    background-image: url(../assets/4.jpg);
    z-index: 2;
    transform: rotateY(-40deg) rotateZ(-25deg) rotateX(30deg);
  }
}
.recon__light {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.1;
  width: 200px;
  height: 200px;
  background: $white;
  filter: blur(40px);
  border-radius: 100px;
  transform: rotateX(45deg) translateX(140px) translateY(220px);
  transform-origin: center center;
}

.visualize {
  position: relative;
  margin: 0 auto;
  max-width: 980px;
  display: flex;
  flex-direction: column;
  align-items: center;
  & img {
    margin-top: 48px;
    pointer-events: none;
  }
}
.visualize__title {
  color: $white;
  font-size: 24px;
}
.visualize__desc {
  max-width: 450px;
  margin-top: 12px;
  color: $steel;
  font-size: 18px;
  line-height: 1.5;
  text-align: center;
}
.visualize__window {
  overflow: hidden;
  margin-top: 36px;
  width: 100%;
  border-radius: 8px;
}
.titlebar {
  padding-left: 16px;
  display: flex;
  align-items: center;
  width: 100%;
  height: 45px;
  background: rgba($steel, 0.4);
}
.titlebar__control {
  margin-right: 10px;
  width: 10px;
  height: 10px;
  background: rgba($white, 0.3);
  border-radius: 8px;
}
.visualize__iframe {
  display: block;
  width: 100%;
  height: 410px;
  overflow: hidden;
}

footer {
  margin: 0 auto;
  padding: 80px 0;
  max-width: 980px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.footer__logo {
  display: inline-block;
  margin-top: 37px;
  transform: translateX(-5px);
}

.button {
  display: flex;
  align-items: center;
  padding: 0 24px;
  height: 45px;
  color: $white;
  font-weight: 600;
  border-radius: 4px;
  background: $green;
  text-decoration: none;
  box-shadow: 0 1px 3px rgba(#000, 0.1);
  transition: 0.15s ease all;
  &:active {
    background: darken($green, 4%);
    transform: translateY(1px);
  }
}

.attribution {
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 12px 16px;
  color: $white;
  background: rgba(#000, 0.5);
  border-top-left-radius: 6px;
}

.terms {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 12px 16px;
  color: $white;
  background: rgba(#000, 0.5);
  border-top-right-radius: 6px;
}

.terms a {
  color: $white;
  text-decoration: none;
}

@media (max-width: 1024px) {
  html {
    overflow-x: hidden;
  }
  header {
    padding: 0 24px;
  }
  .header__content {
    justify-content: center;
  }
  nav a {
    font-size: 13px;
  }
  nav a:not(:last-child) {
    margin-right: 32px;
  }

  .hero {
    overflow: hidden;
    padding: 64px 24px 0 24px;
  }
  .hero__title {
    font-size: 28px;
  }
  .hero__desc {
    font-size: 16px;
  }
  .visualize {
    padding: 0 24px;
  }
  .visualize__title {
    font-size: 24px;
  }
  .visualize__desc {
    font-size: 16px;
  }

  .footer {
    padding: 48px 0;
  }
}

@media (max-width: 700px) {
  .header__sponsor {
    display: none;
  }
  .hero {
    padding-top: 0;
  }
  .hero__visual {
  }
}
